"use client";

import {useTheme} from "next-themes";
import {SunIcon} from "@radix-ui/react-icons";

const ThemeSwitch = () => {
    const {theme, setTheme} = useTheme();

    return (
        <div className="inline-flex items-center">
            <SunIcon className="w-4 h-4 mr-2"/>
            <select
                aria-label={'Theme'}
                name="themeSwitch"
                value={theme}
                onChange={e => setTheme(e.target.value)}>
                <option aria-label={'System'} value="system">System</option>
                <option aria-label={'Dark'} value="dark">Dark</option>
                <option aria-label={'Light'} value="light">Light</option>
            </select>
        </div>
    );
};

export default ThemeSwitch;
